<template>
  <div class="map-select-layout fullbox withTopBar" >
    <van-nav-bar title="选择位置" left-text="返回" left-arrow safe-area-inset-top fixed @click-left="onClickLeft"></van-nav-bar>
    <div id="mapiframe" v-if="pageSrc!=''">
      <iframe class="map-item" v-if="ismap" id="getAddress" @load="loadiframe"
              :src="pageSrc"
              style="width:100%; height:100%;position: absolute;z-index:22222;"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name:"map-select",
  props:{
    ismap:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return{
      query:{},
      pageSrc:""
    }
  },
  mounted(){
    this.query = this.$route.query
    const center = localStorage.getItem("positionCenter")
    this.pageSrc = 'https://m.amap.com/picker/?keywords=写字楼,小区,学校&key=608d75903d29ad471362f8c58c550daf&center='+center
    // console.log(this.pageSrc)
  },
  methods:{
    loadiframe() {
      const _this = this
      let iframe = document.getElementById('getAddress').contentWindow;
      iframe.postMessage('hello', 'https://m.amap.com/picker/');
      window.addEventListener("message", _this.callBack = function (e) {
        // console.log(e)
        if (e.data.command != "COMMAND_GET_TITLE") {
          //业务代码
          // console.log(e)   
          if(e.data.location){
            _this.getPositionName(e.data)
            //_this.index()
          }
        }
      }.bind(this), true);
    },
    // index(){
    //   const _this = this
    //     _this.$api.common.getDisOrderList({
    //         page:1,
    //         rowCount:10
    //     }).then(res=>{
    //         if(res.code =='0' && res.data){
    //           _this.$store.commit('changePoint', {
    //             longitude:'1111',
    //             latitude:'22222',
    //             province:'测试1',
    //             city:'测试2',
    //             district:'测试3',
    //           })
    //           _this.onClickLeft()
    //         }
    //     })
    // },
    getPositionName(point){
      const _this = this
      this.$api.common.getPositionName({
        location:point.location
      }).then(res=> {
        console.log(res,res.code == '0' && res.data.status == '1' && res.data.infocode =="10000")
        if(res.code == '0' && res.data.status == '1' && res.data.infocode =="10000"){
          let arr = point.location.split(",")
          point.longitude = arr[0]//经度
          point.latitude = arr[1]//纬度
          point.province= res.data.regeocode.addressComponent.province,//省
          point.city=res.data.regeocode.addressComponent.city,//市
          point.district=res.data.regeocode.addressComponent.district,//区
          // latitude:"",
          // longitude:"",
          console.log(point)
          _this.$store.commit('changePoint', point)
          _this.onClickLeft()
        }
      })
    },
    onClickLeft(){
      this.$router.push({
        name: "user-input",
        query:this.query
      })
    }
  },
  // beforeDestroy(){
  //   const _this = this
  //   window.removeEventListener('message',_this.callBack,true)
  // }
}
</script>

<style lang="scss">
#mapiframe{
  position: relative;
  height: calc(100% - 46px);
  overflow: hidden;
  box-sizing: border-box;
  #getAddress{
    border: none;
  }
}
</style>